import IconAgv from "@/components/Icon/brands/IconAgv.svg";
import IconAlpinestarts from "@/components/Icon/brands/IconAlpinestarts.svg";
import IconAkrapovic from "@/components/Icon/brands/IconAkrapovic.svg";
import IconDririder from "@/components/Icon/brands/IconDririder.svg";
import IconRG from "@/components/Icon/brands/IconRG.svg";
import IconShoei from "@/components/Icon/brands/IconShoei.svg";
import IconArrowRight from "@/components/Icon/IconArrowRight.svg";
import IconArrowLeft from "@/components/Icon/IconArrowLeft.svg";

import styles from "./styles.module.scss";
import classNames from "classnames";
import Link from "next/link";
import Image from "next/image";

export default function Component({ open, onClose, onCloseNavigation }) {
  const menu = {
    title: "Brands",
    url: "/brands",
    subMenu: [
      {
        title: "Alpinestars",
        url: "/brands/alpinestars",
        icon: IconAlpinestarts.src,
      },
      {
        title: "Dririder",
        url: "/brands/dririder",
        icon: IconDririder.src,
      },
      {
        title: "Shoei",
        url: "/brands/shoei",
        icon: IconShoei.src,
      },
      {
        title: "AGV",
        url: "/brands/agv",
        icon: IconAgv.src,
      },
      {
        title: "Akrapovic",
        url: "/brands/Akrapovic",
        icon: IconAkrapovic.src,
      },
      {
        title: "R&G",
        url: "/brands/r-g",
        icon: IconRG.src,
      },
    ],
  };
  const handleBackToMenu = () => {
    document.querySelector(`#menu-bar`)?.classList.add("show-menu");
    document.querySelector(`#menu-bar`)?.classList.remove("hidden-menu");
    onClose();
  };

  return (
    <div
      id="brands-menu"
      className={classNames(styles.subMenu, styles.subMenu1, open ? styles["show-menu"] : styles["hidden-menu"])}
    >
      <div
        className="w-full py-5 flex lg:hidden items-center gap-2 px-5 border-b-[1px] border-grey cursor-pointer"
        onClick={handleBackToMenu}
      >
        <Image src={IconArrowLeft.src} width="16" height="16" alt="IconArrowLeft" />
        <p className={classNames("text-sm font-bold font-roboto")}>Back</p>
      </div>
      <div className="w-full h-fit flex flex-col lg:flex-row justify-between items-start lg:items-center gap-2 px-6 py-3 lg:py-7">
        <h5 className="ml-0 lg:ml-2">{menu.title}</h5>
        {menu.url && (
          <Link href={menu.url} className="font-bold" onClick={() => onCloseNavigation(false)}>
            View all
          </Link>
        )}
      </div>
      {menu.subMenu &&
        menu.subMenu.length > 0 &&
        menu.subMenu.map((item1, index) => (
          <div
            key={`menu-item-1-${item1.title}`}
            className={classNames("w-full px-6 lg:px-7 hover:bg-light-grey", styles.menuItem2)}
          >
            <Link href={item1.url} onClick={() => onCloseNavigation(false)}>
              <div className="w-full h-[56px] flex items-center gap-4 border-b-[1px] border-grey-100 ">
                <Image src={item1.icon} alt={item1.title} width={40} height={40} />
                <p className="text-base font-[500]">{item1.title}</p>
              </div>
            </Link>
          </div>
        ))}
      <div className="mt-6 lg:mt-[56px]">
        <p className="px-6 lg:px-7 mb-1 text-grey text-sm">Quick Links</p>
        <div className={classNames("w-full px-6 lg:px-7 hover:bg-light-grey")}>
          <Link href="/brands" onClick={() => onCloseNavigation(false)}>
            <div className="w-full h-[56px] flex items-center gap-4 border-b-[1px] border-grey-100 justify-between">
              <p className="text-base font-[500]">View all brands</p>
              <Image src={IconArrowRight.src} width="24" height="24" alt="IconArrowRight" />
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
}
