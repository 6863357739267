import(/* webpackMode: "eager" */ "/vercel/path0/app/globals.scss");
import(/* webpackMode: "eager" */ "/vercel/path0/components/CartButton/index.jsx");
import(/* webpackMode: "eager" */ "/vercel/path0/components/Icon/IconCart.svg");
import(/* webpackMode: "eager" */ "/vercel/path0/components/Icon/IconCartMobile.svg");
import(/* webpackMode: "eager" */ "/vercel/path0/components/Icon/IconMinus.svg");
import(/* webpackMode: "eager" */ "/vercel/path0/components/Icon/IconPlus.svg");
import(/* webpackMode: "eager" */ "/vercel/path0/components/Icon/IconPrivacy.svg");
import(/* webpackMode: "eager" */ "/vercel/path0/components/Icon/IconShop.svg");
import(/* webpackMode: "eager" */ "/vercel/path0/components/Icon/IconWidget.svg");
import(/* webpackMode: "eager" */ "/vercel/path0/components/Layout/Footer/styles.module.scss");
import(/* webpackMode: "eager" */ "/vercel/path0/components/Layout/Header/SearchBox/index.jsx");
import(/* webpackMode: "eager" */ "/vercel/path0/components/Layout/Navigation/index.jsx");
import(/* webpackMode: "eager" */ "/vercel/path0/components/LoadingFreshChat/index.jsx");
import(/* webpackMode: "eager" */ "/vercel/path0/components/PageAttributes/index.jsx");
import(/* webpackMode: "eager" */ "/vercel/path0/components/UserAvatar/index.jsx");
import(/* webpackMode: "eager" */ "/vercel/path0/lib/saleor/reactSaleor/components/SaleorProvider/SaleorProvider.js");
import(/* webpackMode: "eager" */ "/vercel/path0/lib/saleor/reactSaleor/helpers.ts");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@vercel/speed-insights/dist/next/index.mjs");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/image-component.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/link.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/script.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-no-ssr.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/nextjs-toploader/dist/index.js")