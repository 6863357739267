"use client";

import { GTMPageView, spaGlobalTagEvent } from "@/lib/gtm/events";
import { useAuth } from "@/lib/saleor/reactSaleor";
import { usePathname, useSearchParams } from "next/navigation";
import { useEffect, useState } from "react";

export default function PageAttributes() {
  const { authenticated } = useAuth();

  const pathName = usePathname();
  const searchParams = useSearchParams();

  const [currentPath, setCurrentPath] = useState(pathName);

  const getPageInfo = (pageTitle, user) => {
    let url = window.location.href;
    const _breadcrumbs = window.location.pathname.replace("/", "").split("/");
    let qs = url.split("?")[1] || "";

    const item = GTMPageView(url, pageTitle, qs, _breadcrumbs, Boolean(user.data), user.device);
    return item;
  };

  const getDeviceType = () => {
    if (typeof window === "undefined") return "desktop";
    const width = window.innerWidth;
    if (width < 768) {
      return "mobile";
    } else if (width < 1024) {
      return "tablet";
    } else {
      return "desktop";
    }
  };

  const sendGtmData = async (event = null) => {
    const renderDeviceType = getDeviceType();
    const pageTitle = document.title;

    const item = getPageInfo(pageTitle, {
      data: authenticated,
      device: renderDeviceType,
    });

    if (event) {
      item["event"] = event;
    }
    (await import("react-gtm-module")).default.dataLayer({
      dataLayer: { ...item },
    });
  };
  useEffect(() => {
    sendGtmData();
  }, []);

  useEffect(() => {
    if (currentPath !== pathName) {
      const isCompleteCheckout = searchParams.get("orderToken");
      if (!isCompleteCheckout) {
        sendGtmData("PageView");
      }
      spaGlobalTagEvent(pathName);
      setCurrentPath(pathName);
    }
  }, [pathName]);

  return null;
}
