"use client";
import Link from "next/link";
import Image from "next/image";
import IconProfile from "@/components/Icon/IconProfile.svg";
import IconProfileMobile from "@/components/Icon/IconProfileMobile.svg";
import { useUserDetails } from "@/lib/saleor/reactSaleor";
import { LocalRepository } from "@/lib/saleor/repository";
import { useEffect } from "react";

const repository = new LocalRepository();

export default function UserAvatar({}) {
  const { data: userData } = useUserDetails();
  let placeholder;
  if (userData) {
    const firstName = userData.firstName || "";
    const lastName = userData.lastName || "";
    placeholder = firstName[0]?.toUpperCase() + lastName[0]?.toUpperCase();
    if (!placeholder) placeholder = userData.email[0]?.toUpperCase();
  }
  useEffect(() => {
    if (userData) {
      repository.setCheckout(userData.checkout || {});
    }
  }, [userData?.checkout?.lines]);
  return (
    <Link href={userData ? "/profile" : "/login"}>
      <button className="w-fit lg:w-[80px] h-[64px] lg:h-[80px] flex items-center justify-center border-l-0 lg:border-l-[1px] border-grey">
        {userData ? (
          <div className="w-7 lg:w-8 h-7 lg:h-8 rounded-full bg-grey-200 flex items-center justify-center text-sm font-bold">
            {placeholder}
          </div>
        ) : (
          <>
            <Image src={IconProfile.src} alt="IconProfile" className="hidden lg:block" width={24} height={24} />
            <Image
              src={IconProfileMobile.src}
              alt="IconProfileMobile"
              className="block lg:hidden"
              width={16}
              height={16}
            />
          </>
        )}
      </button>
    </Link>
  );
}
