"use client";
import classNames from "classnames";
import Link from "next/link";
import styles from "./styles.module.scss";
import IconArrowLeft from "@/components/Icon/IconArrowLeft.svg";

import Image from "next/image";
import { useEffect, useState } from "react";
import { getAcessoriesQuery } from "@/utils/common";

export default function Component({ data, id, open, onOpen, onClose, onCloseNavigation }) {
  const [subMenuIndex, setSubMenuIndex] = useState();
  const [vehicleID, setVehicleID] = useState("");

  const getLink = url => {
    var updateURL = url || "";
    if (url && url.includes("accessories") && vehicleID) {
      updateURL += `?vehicle_ids=${vehicleID}`;
    }
    return updateURL;
  };

  const handleBackToMenu = () => {
    document.querySelector(`#menu-bar`)?.classList.add("show-menu");
    document.querySelector(`#menu-bar`)?.classList.remove("hidden-menu");
    onClose();
    setSubMenuIndex("");
  };
  const handleBackToSubMenu = () => {
    setSubMenuIndex("");
    onOpen();
  };

  const handleClick = index => {
    if (window.innerWidth < 1024) {
      onClose();
    }
    setSubMenuIndex(index);
  };

  useEffect(() => {
    if (!open && window.innerWidth > 1024) {
      setSubMenuIndex("");
    }
  }, [open]);

  useEffect(() => {
    const listenStorageChange = () => {
      const vehicleNUmber = getAcessoriesQuery()?.vehicleNumber;
      setVehicleID(vehicleNUmber);
    };
    listenStorageChange();
    window.addEventListener("storage", listenStorageChange);
    return () => window.removeEventListener("storage", listenStorageChange);
  }, []);

  return (
    data && (
      <>
        <div
          id={id}
          className={classNames("sub-menu-item", styles.subMenu, styles.subMenu1, open ? "show-menu" : "hidden-menu")}
        >
          <div
            className="w-full py-5 flex lg:hidden items-center gap-2 px-5 border-b-[1px] border-grey cursor-pointer"
            onClick={handleBackToMenu}
          >
            <Image src={IconArrowLeft.src} width="16" height="16" alt="" />
            <p className={classNames("text-sm font-bold font-roboto")}>Back</p>
          </div>
          <div className="w-full h-fit flex flex-col lg:flex-row justify-between items-start lg:items-center gap-2 px-6 py-3 lg:py-7">
            <h5 className="ml-0 lg:ml-2">{data.title}</h5>
            {data.url && (
              <Link href={getLink(data.url)} className="font-bold text-sm" onClick={() => onCloseNavigation(false)}>
                View all
              </Link>
            )}
          </div>
          {data.subMenu &&
            data.subMenu.length > 0 &&
            data.subMenu.map((item, index) => (
              <div
                key={`menu-item-1-${item.title}`}
                id={`${id}__menu-item__${index}`}
                className={classNames(
                  "sub-menu-item w-full px-6 lg:px-7 hover:bg-light-grey cursor-pointer",
                  styles.menuItem2,
                  subMenuIndex === index && "bg-light-grey"
                )}
                onClick={() => handleClick(index)}
              >
                {!item.subMenu && item.url ? (
                  <Link href={getLink(item.url)} onClick={() => onCloseNavigation(false)}>
                    <div className="w-full h-[56px] flex items-center border-b-[1px] border-grey-100 ">
                      <div className={styles.menuIcon}>
                        {item.icon && <Image src={item.icon} width={40} height={40} className="mr-4" alt="" />}
                      </div>
                      <p className="text-base font-[500]">{item.title}</p>
                    </div>
                  </Link>
                ) : (
                  <div className="w-full h-[56px] flex items-center border-b-[1px] border-grey-100 ">
                    <div className={styles.menuIcon}>
                      {item.icon && <Image src={item.icon} width={40} height={40} className="mr-4" alt="" />}
                    </div>
                    <p className="text-base font-[500]">{item.title}</p>
                  </div>
                )}
              </div>
            ))}
        </div>
        {data.subMenu &&
          data.subMenu.length > 0 &&
          data.subMenu.map(
            (item, index) =>
              item.subMenu &&
              item.subMenu.length > 0 && (
                <div
                  key={`menu-item-2-${item.title}`}
                  id={`${id}__child-sub-menu-${index}`}
                  className={classNames(
                    "child-sub-menu",
                    styles.subMenu2,
                    styles.childSubMenu,
                    index === subMenuIndex ? "show-menu" : "hidden-menu"
                  )}
                >
                  <div
                    className="w-full py-5 flex lg:hidden items-center gap-2 px-5 border-b-[1px] border-grey cursor-pointer"
                    onClick={handleBackToSubMenu}
                  >
                    <Image src={IconArrowLeft.src} width="16" height="16" alt="" />
                    <p className={classNames("text-sm font-bold font-roboto")}>Back</p>
                  </div>
                  <div className="w-full h-fit flex flex-col lg:flex-row justify-between items-start lg:items-center gap-2 px-6 py-3 lg:py-7">
                    <h5 className="ml-0 lg:ml-2">{item.title}</h5>
                    {item.url && (
                      <Link
                        href={getLink(item.url)}
                        className="font-bold text-sm"
                        onClick={() => onCloseNavigation(false)}
                      >
                        View all
                      </Link>
                    )}
                  </div>
                  {item.subMenu.map((childItem, index) => (
                    <Link
                      key={`menu-item-2-${childItem.title}`}
                      href={getLink(childItem.url)}
                      className={classNames("w-full px-6 lg:px-7  hover:bg-light-grey")}
                      onClick={() => {
                        setSubMenuIndex("");
                        onCloseNavigation(false);
                      }}
                    >
                      <div className="w-full h-[56px] flex items-center border-b-[1px] border-grey-100 ">
                        <p className="text-base font-[500]">{childItem.title}</p>
                      </div>
                    </Link>
                  ))}
                </div>
              )
          )}
      </>
    )
  );
}
