import SubMenu from "./SubMenu";

import IconLargeAdventureBoots from "@/components/Icon/IconLargeAdventureBoots.svg";
import IconLargeAdventureGloves from "@/components/Icon/IconLargeAdventureGloves.svg";
import IconLargeAdventureHelmet from "@/components/Icon/IconLargeAdventureHelmet.svg";
import IconLargeAdventureJacket from "@/components/Icon/IconLargeAdventureJacket.svg";
import IconLargeAdventurePants from "@/components/Icon/IconLargeAdventurePants.svg";
import IconLargeAdventureVisors from "@/components/Icon/IconLargeAdventureVisors.svg";
import IconLargeGearClearance from "@/components/Icon/IconLargeGearClearance.svg";

export default function Component({ open, onOpen, onClose, onCloseNavigation }) {
  const menu = {
    title: "Adventure Gear",
    url: "/adventure-gear",
    subMenu: [
      {
        title: "Boots",
        url: "/adventure-gear/adventure-boots",
        icon: IconLargeAdventureBoots.src,
        subMenu: [
          {
            title: "Short Boots",
            url: "/adventure-gear/adventure-boots/adv-short-boots",
            count: 46,
          },
          {
            title: "Full Length Boots ",
            url: "/adventure-gear/adventure-boots/adv-full-length-boots",
            count: 46,
          },
        ],
      },
      {
        title: "Clearance",
        url: "/adventure-gear/adventure-clearance",
        icon: IconLargeGearClearance.src,
        subMenu: [
          {
            title: "Under $50",
            url: "/adventure-gear/adventure-clearance/adv-under-50",
            count: 46,
          },
          {
            title: "Under $100",
            url: "/adventure-gear/adventure-clearance/adv-under-100",
            count: 46,
          },
          {
            title: "Under $200",
            url: "/adventure-gear/adventure-clearance/adv-under-200",
            count: 46,
          },
          {
            title: "Under $500",
            url: "/adventure-gear/adventure-clearance/adv-under-500",
            count: 46,
          },
        ],
      },
      {
        title: "Gloves",
        url: "/adventure-gear/adventure-gloves",
        icon: IconLargeAdventureGloves.src,
        subMenu: [
          {
            title: "Short Cuff Gloves",
            url: "/adventure-gear/adventure-gloves/adv-short-cuff-gloves",
            count: 46,
          },
          {
            title: "Long Cuff Gloves",
            url: "/adventure-gear/adventure-gloves/adv-long-cuff-gloves",
            url: "/adventure-gear/adventure-gloves/womens-adventure-gloves",
            count: 46,
          },
        ],
      },
      {
        title: "Helmets",
        url: "/adventure-gear/adventure-helmets",
        icon: IconLargeAdventureHelmet.src,
        subMenu: [
          {
            title: "Helmets",
            url: "/adventure-gear/adventure-helmets/adv-helmets",
            count: 46,
          },
          {
            title: "Helmet Accessories",
            url: "/adventure-gear/adventure-helmets/adv-helmet-accessories",
            count: 46,
          },
        ],
      },
      {
        title: "Jackets",
        url: "/adventure-gear/adventure-jackets",
        icon: IconLargeAdventureJacket.src,
        subMenu: [
          {
            title: "Men's Jackets",
            url: "/adventure-gear/adventure-jackets/adv-mens-jackets",
            count: 46,
          },
          {
            title: "Women's Jackets",
            url: "/adventure-gear/adventure-jackets/adv-womens-jackets",
            count: 46,
          },
        ],
      },
      {
        title: "Pants",
        url: "/adventure-gear/adventure-pants",
        icon: IconLargeAdventurePants.src,
        subMenu: [
          {
            title: "Men's Adventure Pants",
            url: "/adventure-gear/adventure-pants/adv-mens-pants",
            count: 46,
          },
          {
            title: "Women's Adventure Pants",
            url: "/adventure-gear/adventure-pants/adv-womens-pants",
            count: 46,
          },
        ],
      },
      {
        title: "Visors",
        url: "/adventure-gear/adventure-visors",
        icon: IconLargeAdventureVisors.src,
        subMenu: [
          {
            title: "Full Face Visors",
            url: "/adventure-gear/adventure-visors/adv-full-face-visors",
            count: 46,
          },
          {
            title: "Pinlocks",
            url: "/adventure-gear/adventure-visors/adv-pinlocks",
            count: 46,
          },
          {
            title: "Dual Sport Visors",
            url: "/adventure-gear/adventure-visors/adv-dual-sport-visors",
            count: 46,
          },
        ],
      },
    ],
  };
  return <SubMenu data={menu} open={open} onOpen={onOpen} onClose={onClose} onCloseNavigation={onCloseNavigation} />;
}
