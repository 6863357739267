import Link from "next/link";
import styles from "./styles.module.scss";
import classNames from "classnames";
import Image from "next/image";
import IconArrowLeft from "@/components/Icon/IconArrowLeft.svg";

export default function Component({ open, onClose, onCloseNavigation }) {
  const handleBackToMenu = () => {
    document.querySelector(`#menu-bar`)?.classList.add("show-menu");
    document.querySelector(`#menu-bar`)?.classList.remove("hidden-menu");
    onClose();
  };

  return (
    <div
      id="contact-menu"
      className={classNames(styles.subMenu, styles.subMenu1, open ? styles["show-menu"] : styles["hidden-menu"])}
    >
      <div
        className="w-full py-5 flex lg:hidden items-center gap-2 px-5 border-b-[1px] border-grey cursor-pointer"
        onClick={handleBackToMenu}
      >
        <Image src={IconArrowLeft.src} width="16" height="16" alt="IconArrowLeft" />
        <p className={classNames("text-sm font-bold font-roboto")}>Back</p>
      </div>
      <div className="w-full h-fit flex justify-between items-center px-6 lg:px-7 py-4 lg:py-6">
        <h4 className="ml-0 lg:ml-2 ">Contact Bikebiz</h4>
      </div>
      <div className="px-6 lg:px-7">
        <div className="flex flex-col border-b-[1px] border-black pb-7">
          <p className="font-bold text-lg mb-3">Bikebiz Parramatta</p>
          <div className="flex border-[1px] border-light-grey bg-white">
            <div className="w-1/4 py-2">
              <div className="w-full h-[30px] lg:h-[54px] flex justify-center items-center border-r-[1px] border-light-grey">
                <Image
                  src="/brand-yamaha.webp"
                  className="w-[45px] lg:w-[55px]"
                  width={55}
                  height={18}
                  alt="brand-yamaha"
                />
              </div>
            </div>
            <div className="w-1/4 py-2">
              <div className="w-full h-[30px] lg:h-[54px] flex justify-center items-center border-r-[1px] border-light-grey">
                <Image src="/brand-suzuki.png" width={75} height={40} alt="brand-suzuki" />
              </div>
            </div>
            <div className="w-1/4 py-2">
              <div className="w-full h-[30px] lg:h-[54px] flex justify-center items-center border-r-[1px] border-light-grey">
                <Image
                  src="/brand-aprilia.png"
                  className="w-[45px] lg:w-[62px] h-[20px] lg:h-[48px]"
                  width={62}
                  height={48}
                  alt="brand-aprilia"
                />
              </div>
            </div>
            <div className="w-1/4 py-2">
              <div className="w-full h-[30px] lg:h-[54px] flex justify-center items-center">
                <Image
                  src="/brand-kymco.png"
                  className="w-[55px] lg:w-[75px]"
                  width={75}
                  height={30}
                  alt="brand-kymco"
                />
              </div>
            </div>
          </div>
          <div className="py-3 border-b-[1px] border-light-grey">
            <Link href="#" className="underline" onClick={() => onCloseNavigation(false)}>
              7 Great Western Hwy, Parramatta, NSW, 2150
            </Link>
          </div>
          <div className="py-3 border-b-[1px] border-light-grey flex gap-2">
            <p className="font-bold text-sm">Motorcycles</p>
            <p className="text-grey-400 text-sm">(02) 8830 0500</p>
          </div>
          <div className="py-3 border-b-[1px] border-light-grey flex gap-2">
            <p className="font-bold text-sm">Accessories & Spares</p>
            <p className="text-grey-400 text-sm">(02) 8830 0500</p>
          </div>
          <div className="py-3 border-b-[1px] border-light-grey flex gap-2">
            <p className="font-bold text-sm">Workshop</p>
            <p className="text-grey-400 text-sm">(02) 8830 0511</p>
          </div>
          <Link href="#" onClick={() => onCloseNavigation(false)}>
            <button className="py-2 px-6 mt-4 rounded-full border-[1px] border-black font-bold">Learn more</button>
          </Link>
        </div>
        <div className="flex flex-col mt-7">
          <p className="font-bold text-lg mb-3">Bikebiz Granville</p>
          <div className="flex border-[1px] border-light-grey bg-white">
            <div className="w-1/4 py-2">
              <div className="w-full h-[30px] lg:h-[54px] flex justify-center items-center border-r-[1px] border-light-grey">
                <Image
                  src="/brand-kawasaki.png"
                  className="w-[50px] lg:w-[75px]"
                  width={75}
                  height={12}
                  alt="brand-kawasaki"
                />
              </div>
            </div>
            <div className="w-1/4 py-2">
              <div className="w-full h-[30px] lg:h-[54px] flex justify-center items-center border-r-[1px] border-light-grey">
                <Image src="/brand-honda.png" width={34} height={27} alt="brand-honda" />
              </div>
            </div>
            <div className="w-1/4 py-2">
              <div className="w-full h-[30px] lg:h-[54px] flex justify-center items-center border-r-[1px] border-light-grey">
                <Image src="/brand-triumph.png" width={70} height={13} alt="brand-triumph" />
              </div>
            </div>
            <div className="w-1/4 py-2">
              <div className="w-full h-[30px] lg:h-[54px] flex justify-center items-center">
                <Image src="/brand-bmw.png" className="w-[55px] lg:w-[75px]" width={75} height={30} alt="brand-bmw" />
              </div>
            </div>
          </div>
          <div className="py-3 border-b-[1px] border-light-grey">
            <Link href="#" className="underline" onClick={() => onCloseNavigation(false)}>
              274 Parramatta Rd, Granville NSW, 2142
            </Link>
          </div>
          <div className="py-3 border-b-[1px] border-light-grey flex gap-2">
            <p className="font-bold text-sm">Motorcycles</p>
            <p className="text-grey-400 text-sm">(02) 9682 2999</p>
          </div>
          <div className="py-3 border-b-[1px] border-light-grey flex gap-2">
            <p className="font-bold text-sm">Accessories & Spares</p>
            <p className="text-grey-400 text-sm">(02) 9682 2999</p>
          </div>
          <div className="py-3 border-b-[1px] border-light-grey flex gap-2">
            <p className="font-bold text-sm">Workshop</p>
            <p className="text-grey-400 text-sm">(02) 9682 2916</p>
          </div>
          <Link href="#" onClick={() => onCloseNavigation(false)}>
            <button className="py-2 px-6 mt-4 rounded-full border-[1px] border-black font-bold">Learn more</button>
          </Link>
        </div>
      </div>
    </div>
  );
}
