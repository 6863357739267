import { createCookie, removeCookie } from "@/services/cookie";
import remove from "lodash/remove";
import uniq from "lodash/uniq";

export const capitalize = str => {
  try {
    if (!str) return "";
    return str
      .replaceAll("%20", " ")
      .split(/[-+ ]+/)
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  } catch (error) {
    console.log(error, str);
  }
};

export const convertToSlug = text => {
  if (!text) return "";
  return text.toString().replaceAll(/&/g, "").replaceAll(/\s+/g, "+");
};

export const getUrl = (pathName, searchParams) => {
  let query = [];

  Object.keys(searchParams).forEach(key => {
    if (typeof searchParams[key] === "object") {
      searchParams[key].forEach(value => {
        query.push(`${key}=${convertToSlug(value)}`);
      });
    } else {
      query.push(`${key}=${convertToSlug(searchParams[key])}`);
    }
  });
  if (query.length === 0) return pathName;

  return `${pathName}?${query.join("&")}`;
};

export const convertCategoryForBrand = (brand, brandCollection) => {
  const collection = brandCollection
    .split("-")
    .filter(item => !brand.split("-").includes(item))
    .join("-");
  return collection;
};

export const groupBy = (listObj, key) => {
  return listObj.reduce((obj, item) => {
    (obj[item[key]] = obj[item[key]] || []).push(item);
    return obj;
  }, {});
};

export const generateVehiclesArray = (data, level1 = "make", level2 = "year") => {
  let res = groupBy(data, level1);

  //Group by motorcycle_year
  Object.keys(res).forEach(key => {
    let resYearGrouped = groupBy(res[key], level2);
    res[key] = Object.keys(resYearGrouped)
      .sort((a, b) => parseInt(b) - parseInt(a))
      .map(key => {
        let obj = {};
        obj[key] = resYearGrouped[key];
        return obj;
      });
  });
  return res;
};

export const getPaginationLinks = (url, page, pageSize, totalCount) => {
  const linkTags = [];
  const totalPages = Math.ceil(totalCount / pageSize);
  if (page > 1) {
    linkTags.push({ rel: "prev", url: `${url}?p=${page - 1}` });
  }
  if (page < totalPages) {
    linkTags.push({ rel: "next", url: `${url}?p=${page + 1}` });
  }
  return linkTags;
};

export const calcReviewSummary = (reviews, productId) => {
  const reviewSummary = {
    productId,
    rating: reviews.total > 0 && reviews.rating ? reviews.rating : 0,
    total: reviews.total || 0,
  };
  const groupByRating = groupBy(reviews.data, "reviewRating");

  const breakdown = Array(5)
    .fill()
    .map((_, index) => index + 1)
    .reduce((acc, rating) => {
      return {
        ...acc,
        [`rating${rating}`]: groupByRating[rating]?.length || 0,
      };
    }, {});
  reviewSummary["breakdown"] = breakdown;
  return reviewSummary;
};

export const reflectAttributesValues = variant => {
  const values = variant?.attributes
    ?.map(attr => {
      return attr.attribute
        ? {
            attribute: attr.attribute.name.replace("Sizes", "Size"),
            value: attr.values[0]?.value,
          }
        : null;
    })
    .filter(Boolean)
    .sort((a, b) => {
      if (a.attribute > b.attribute) {
        return -1;
      }
      if (b.attribute > a.attribute) {
        return 1;
      }
      return 0;
    });
  return values || [];
};

export const getVariantAttributeValue = (variant, attributeName) => {
  if (!variant?.attributes) {
    return null;
  }
  const attribute = variant.attributes.find(attr => attr.attribute.name.toLowerCase() === attributeName.toLowerCase());
  if (!attribute) {
    return null;
  }
  return attribute.values[0];
};

export const getVariantMainThumbnail = variant => {
  let thumbnail = variant?.product?.thumbnail;
  if (!thumbnail) return null;
  let colorJson = {};
  //Get color attribute
  const colorAttribute = getVariantAttributeValue(variant, "Colour");
  if (colorAttribute) {
    //Get product description json, color information is located in here
    const productDescriptionJson = variant.product.descriptionJson;
    if (productDescriptionJson) {
      try {
        //Extract color information
        colorJson = JSON.parse(productDescriptionJson);
      } catch (error) {
        console.error("Couldn't parse product description JSON");
      }
    }
    if (Object.keys(colorJson).length) {
      const color = colorJson["colour"]?.find(item => item.name === colorAttribute.value);

      //To ensure that the thumbnail always has data
      thumbnail = color ? { url: color.mainImage, alt: variant.product.name } : thumbnail;
    }
  }
  return thumbnail;
};

export const createValueAfterRemoveGiftCard = (metadata, productId) => {
  const giftcardMetadata = metadata.filter(item => item.key === "giftCard");
  const currentValue = JSON.parse(giftcardMetadata.length > 0 ? giftcardMetadata[0].value : "[]");
  const value = currentValue.filter(item => item.productID != productId);
  return value;
};

export const removeGiftCardFromCart = (userData, productId, updateMetadataCheckout) => {
  if (userData) {
    const newValue = createValueAfterRemoveGiftCard(userData.checkout?.metadata, productId);
    const variables = {
      checkoutId: userData.checkout?.id,
      input: [
        {
          key: "giftCard",
          value: JSON.stringify(newValue),
        },
      ],
    };
    updateMetadataCheckout({ variables: variables }).then(res => {
      const metadataObject = res.data.updateMetadata;
      if (metadataObject?.errors?.length > 0) {
        console.log("Update metadata failed");
      } else {
        localStorage.setItem("checkout_metadata", JSON.stringify(metadataObject?.item?.metadata));
      }
    });
  } else {
    const metadata = JSON.parse(localStorage.getItem("guest_checkout_metadata"));
    const newValue = createValueAfterRemoveGiftCard(metadata, productId);
    localStorage.setItem(
      "guest_checkout_metadata",
      JSON.stringify([
        {
          __typename: "MetadataItem",
          key: "giftCard",
          value: JSON.stringify(newValue),
        },
      ])
    );
  }
};

export const calculateReviewBarWidth = (ratingCount, total, barWidth) => {
  if (!ratingCount || !total) return 0;
  return (ratingCount / total) * barWidth;
};

export function getAcessoriesQuery() {
  try {
    let accessoriesQuery = JSON.parse(localStorage.getItem("accessoriesQuery"));
    if (accessoriesQuery && accessoriesQuery.histories) {
      const removed = remove(accessoriesQuery.histories, h => {
        return h.id.includes("accessories");
      });

      if (removed.length) {
        setAcessoriesQuery(accessoriesQuery);
      }
    }

    return JSON.parse(localStorage.getItem("accessoriesQuery") || { histories: [] });
  } catch {
    return {};
  }
}

export const setAcessoriesQuery = (query = {}) => {
  // Just keep last 3 items
  let { histories = [] } = query;
  histories = uniq(histories);
  if (histories.length > 3) {
    histories.shift();
  }

  if (!query.vehicleNumber) {
    removeCookie("vehicleNumber");
  } else {
    createCookie("vehicleNumber", query.vehicleNumber);
  }

  query["histories"] = histories;
  localStorage.setItem("accessoriesQuery", JSON.stringify(query));
  if (typeof window !== "undefined") {
    window.dispatchEvent(new Event("storage"));
  }
};

export const removeAcessoriesQuery = () => {
  localStorage.removeItem("accessoriesQuery");
};

export const checkIsStringUrl = url => {
  var regex = new RegExp("^(http|https)://", "i");
  return regex.test(url);
};

export const convertImageURL = src => {
  if (!src) return "/images/placeholder.jpg";
  if (checkIsStringUrl(src)) return src;
  return `${process.env.NEXT_PUBLIC_CF_IMAGE_CDN}${src}`;
};
