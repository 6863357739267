import SubMenu from "./SubMenu";

import IconAprilia from "@/components/Icon/brands/IconAprilia.svg";
import IconBMW from "@/components/Icon/brands/IconBMW.svg";
import IconHonda from "@/components/Icon/brands/IconHonda.svg";
import IconKawasaki from "@/components/Icon/brands/IconKawasaki.svg";
import IconKymco from "@/components/Icon/brands/IconKymco.svg";
import IconSuzuki from "@/components/Icon/brands/IconSuzuki.svg";
import IconTriumph from "@/components/Icon/brands/IconTriumph.svg";
import IconYamaha from "@/components/Icon/brands/IconYamaha.svg";

export default function Component({ open, onOpen, onClose, onCloseNavigation }) {
  const menu = {
    title: "New Motorcycles",
    url: "/new-bikes",
    subMenu: [
      {
        title: "Yamaha",
        url: "/new-bikes/yamaha",
        icon: IconYamaha.src,
        subMenu: [
          {
            title: "Specials offers & deals",
            url: "/new-bikes/yamaha/offers",
          },
          {
            title: "New motorcycles",
            url: "/new-bikes/yamaha/range",
          },
        ],
      },
      {
        title: "Honda",
        url: "/new-bikes/honda",
        icon: IconHonda.src,
        subMenu: [
          {
            title: "Specials offers & deals",
            url: "/new-bikes/honda/offers",
          },
          {
            title: "New motorcycles",
            url: "/new-bikes/honda/range",
          },
        ],
      },
      {
        title: "BMW",
        url: "/new-bikes/bmw",
        icon: IconBMW.src,
        subMenu: [
          {
            title: "Specials offers & deals",
            url: "/new-bikes/bmw/offers",
          },
          {
            title: "New motorcycles",
            url: "/new-bikes/bmw/range",
          },
        ],
      },
      {
        title: "Kawasaki",
        url: "/new-bikes/kawasaki",
        icon: IconKawasaki.src,
        subMenu: [
          {
            title: "Specials offers & deals",
            url: "/new-bikes/kawasaki/offers",
          },
          {
            title: "New motorcycles",
            url: "/new-bikes/kawasaki/range",
          },
        ],
      },
      {
        title: "Triumph",
        url: "/new-bikes/triumph",
        icon: IconTriumph.src,
        subMenu: [
          {
            title: "Specials offers & deals",
            url: "/new-bikes/triumph/offers",
          },
          {
            title: "New motorcycles",
            url: "/new-bikes/triumph/range",
          },
        ],
      },
      {
        title: "Aprilia",
        url: "/new-bikes/aprilia",
        icon: IconAprilia.src,
        subMenu: [
          {
            title: "Specials offers & deals",
            url: "/new-bikes/aprilia/offers",
          },
          {
            title: "New motorcycles",
            url: "/new-bikes/aprilia/range",
          },
        ],
      },
      {
        title: "Suzuki",
        url: "/new-bikes/suzuki",
        icon: IconSuzuki.src,
        subMenu: [
          {
            title: "Specials offers & deals",
            url: "/new-bikes/suzuki/offers",
          },
          {
            title: "New motorcycles",
            url: "/new-bikes/suzuki/range",
          },
        ],
      },
      {
        title: "Kymco",
        url: "/new-bikes/kymco",
        icon: IconKymco.src,
        subMenu: [
          {
            title: "Specials offers & deals",
            url: "/new-bikes/kymco/offers",
          },
          {
            title: "New motorcycles",
            url: "/new-bikes/kymco/range",
          },
        ],
      },
    ],
  };
  return <SubMenu data={menu} open={open} onOpen={onOpen} onClose={onClose} onCloseNavigation={onCloseNavigation} />;
}
