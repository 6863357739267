import SubMenu from "./SubMenu";

import IconLargeGearBoots from "@/components/Icon/IconLargeGearBoots.svg";
import IconLargeGearClearance from "@/components/Icon/IconLargeGearClearance.svg";
import IconLargeGearGloves from "@/components/Icon/IconLargeGearGloves.svg";
import IconLargeGearHelmet from "@/components/Icon/IconLargeGearHelmet.svg";
import IconLargeGearIntercoms from "@/components/Icon/IconLargeGearIntercoms.svg";
import IconLargeGearJacket from "@/components/Icon/IconLargeGearJacket.svg";
import IconLargeGearLeatherSuit from "@/components/Icon/IconLargeGearLeatherSuit.svg";
import IconLargeGearPants from "@/components/Icon/IconLargeGearPants.svg";
import IconLargeGearProtection from "@/components/Icon/IconLargeGearProtection.svg";
import IconLargeGearThermal from "@/components/Icon/IconLargeGearThermal.svg";
import IconLargeGearVisor from "@/components/Icon/IconLargeGearVisor.svg";
import IconLargeGearWetWeather from "@/components/Icon/IconLargeGearWetWeather.svg";
import IconLargeGearCasualWear from "@/components/Icon/IconLargeGearCasualWear.svg";

export default function Component({ open, onOpen, onClose, onCloseNavigation }) {
  const menu = {
    title: "Road Gear",
    url: "/road-gear",
    subMenu: [
      {
        title: "Boots",
        url: "/road-gear/road-boots",
        icon: IconLargeGearBoots.src,
        subMenu: [
          {
            title: "Casual riding shoes",
            url: "/road-gear/road-boots/road-casual-riding-shoes",
          },
          {
            title: "Full length boots",
            url: "/road-gear/road-boots/road-full-length-boots",
          },
          {
            title: "Short boots",
            url: "/road-gear/road-boots/road-short-boots",
          },
          {
            title: "Women’s boots",
            url: "/road-gear/road-boots/road-womens-boots",
          },
        ],
      },
      {
        title: "Clearance",
        url: "/road-gear/road-clearance",
        icon: IconLargeGearClearance.src,
        subMenu: [
          {
            title: "Under $100",
            url: "/road-gear/road-clearance/road-gear-under-100",
          },
          {
            title: "Under $200",
            url: "/road-gear/road-clearance/road-gear-under-200",
          },
          {
            title: "Under $500",
            url: "/road-gear/road-clearance/road-gear-under-500",
          },
        ],
      },
      {
        title: "Helmets",
        url: "/road-gear/road-helmets",
        icon: IconLargeGearHelmet.src,
        subMenu: [
          {
            title: "Open Face Helmets",
            url: "/road-gear/road-helmets/road-open-face-helmets",
          },
          {
            title: "Full Face Helmets",
            url: "/road-gear/road-helmets/road-full-face-helmets",
          },
          {
            title: "Modular Helmets",
            url: "/road-gear/road-helmets/road-modular-helmets",
          },
          {
            title: "Helmet Accessories",
            url: "/road-gear/road-helmets/road-helmet-accessories",
          },
        ],
      },
      {
        title: "Jackets",
        url: "/road-gear/road-jackets",
        icon: IconLargeGearJacket.src,
        subMenu: [
          {
            title: "Short Jackets",
            url: "/road-gear/road-jackets/road-short-jackets",
          },
          {
            title: "3/4 Length Jackets",
            url: "/road-gear/road-jackets/road-34-length-jackets",
          },
          {
            title: "Women's Jackets",
            url: "/road-gear/road-jackets/road-womens-jackets",
          },
        ],
      },
      {
        title: "Pants",
        url: "/road-gear/road-pants",
        icon: IconLargeGearPants.src,
        subMenu: [
          {
            title: "Riding Jeans",
            url: "/road-gear/road-pants/road-riding-jeans",
          },
          {
            title: "Leather Pants",
            url: "/road-gear/road-pants/road-leather-pants",
          },
          {
            title: "Textile Pants",
            url: "/road-gear/road-pants/road-textile-pants",
          },
          {
            title: "Women's Pants",
            url: "/road-gear/road-pants/road-womens-pants",
          },
        ],
      },
      {
        title: "Thermal",
        url: "/road-gear/road-thermal",
        icon: IconLargeGearThermal.src,
        subMenu: [
          {
            title: "Gloves",
            url: "/road-gear/road-thermal/road-thermal-gloves",
          },
          {
            title: "Neck & Head",
            url: "/road-gear/road-thermal/road-thermal-neck-head",
          },
          {
            title: "Pants",
            url: "/road-gear/road-thermal/road-thermal-pants",
          },
          {
            title: "Shirts",
            url: "/road-gear/road-thermal/road-thermal-shirts",
          },
          {
            title: "Socks",
            url: "/road-gear/road-thermal/road-thermal-socks",
          },
        ],
      },
      {
        title: "Wet Weather",
        url: "/road-gear/road-wet-weather",
        icon: IconLargeGearWetWeather.src,
        subMenu: [
          {
            title: "Jackets",
            url: "/road-gear/road-wet-weather/road-wet-weather-jacket",
          },
          {
            title: "One Piece Suits",
            url: "/road-gear/road-wet-weather/road-wet-weather-one-piece-suit",
          },
          {
            title: "Pants",
            url: "/road-gear/road-wet-weather/road-wet-weather-pants",
          },
        ],
      },
      {
        title: "Casual Wear",
        url: "/road-gear/road-casual-wear",
        icon: IconLargeGearCasualWear.src,
        subMenu: [
          {
            title: "Hats",
            url: "/road-gear/road-casual-wear/road-casual-hats",
          },
          {
            title: "Shirts",
            url: "/road-gear/road-casual-wear/road-casual-shirts",
          },
          {
            title: "Jumpers",
            url: "/road-gear/road-casual-wear/road-casual-jumpers",
          },
          {
            title: "Socks",
            url: "/road-gear/road-casual-wear/road-casual-socks",
          },
          {
            title: "Belts",
            url: "/road-gear/road-casual-wear/road-casual-belts",
          },
        ],
      },
      {
        title: "Gloves",
        url: "/road-gear/road-gloves",
        icon: IconLargeGearGloves.src,
        subMenu: [
          {
            title: "Short Cuff Gloves",
            url: "/road-gear/road-gloves/road-short-cuff-gloves",
          },
          {
            title: "Long Cuff Gloves",
            url: "/road-gear/road-gloves/road-long-cuff-gloves",
          },
          {
            title: "Women's Gloves",
            url: "/road-gear/road-gloves/road-womens-gloves",
          },
        ],
      },
      {
        title: "Intercoms",
        url: "/road-gear/road-intercoms-and-bluetooth-kits",
        icon: IconLargeGearIntercoms.src,
        subMenu: [
          {
            title: "Single Units",
            url: "/road-gear/road-intercoms-and-bluetooth-kits-and-bluetooth-kits/single-units",
          },
          {
            title: "Twin Units",
            url: "/road-gear/road-intercoms-and-bluetooth-kits-and-bluetooth-kits/twin-units",
          },
        ],
      },
      {
        title: "Leather Suits",
        url: "/road-gear/road-leather-suits",
        icon: IconLargeGearLeatherSuit.src,
        subMenu: [
          {
            title: "Base Layers",
            url: "/road-gear/road-leather-suits/road-base-layers",
          },
          {
            title: "One Piece Leather Suits",
            url: "/road-gear/road-leather-suits/road-one-piece-leather-suits",
          },
          {
            title: "Two Piece Leather Suits",
            url: "/road-gear/road-leather-suits/road-two-piece-leather-suits",
          },
          {
            title: "Women's Leather Suits",
            url: "/road-gear/road-leather-suits/road-womens-leather-suits",
          },
        ],
      },
      {
        title: "Protection",
        url: "/road-gear/road-protection",
        icon: IconLargeGearProtection.src,
        subMenu: [
          {
            title: "Back Protection",
            url: "/road-gear/road-protection/road-back-protection",
          },
          {
            title: "Chest Protection",
            url: "/road-gear/road-protection/road-chest-protection",
          },
          {
            title: "Ear Protection",
            url: "/road-gear/road-protection/road-ear-protection",
          },
          {
            title: "Elbow Protection",
            url: "/road-gear/road-protection/road-elbow-protection",
          },
          {
            title: "Full Body Protection",
            url: "/road-gear/road-protection/road-full-body-protection",
          },
          {
            title: "Knee Protection",
            url: "/road-gear/road-protection/road-knee-protection",
          },
          {
            title: "Shoulder Protection",
            url: "/road-gear/road-protection/road-shoulder-protection",
          },
        ],
      },
      {
        title: "Visors & Goggles",
        url: "/road-gear/road-visors-and-goggles",
        icon: IconLargeGearVisor.src,
        subMenu: [
          {
            title: "Dual Sport Visors",
            url: "/road-gear/road-visors-and-goggles/road-dual-sport-visors",
          },
          {
            title: "Full Face Visors",
            url: "/road-gear/road-visors-and-goggles/road-full-face-visors",
          },
          {
            title: "Open Face Visors",
            url: "/road-gear/road-visors-and-goggles/road-open-face-visors",
          },
          {
            title: "Pinlocks",
            url: "/road-gear/road-visors-and-goggles/road-pinlocks",
          },
          {
            title: "Road Goggles",
            url: "/road-gear/road-visors-and-goggles/road-road-goggles",
          },
        ],
      },
    ],
  };
  return <SubMenu data={menu} open={open} onOpen={onOpen} onClose={onClose} onCloseNavigation={onCloseNavigation} />;
}
