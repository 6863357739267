import SubMenu from "./SubMenu";

import IconLargeGearClearance from "@/components/Icon/IconLargeGearClearance.svg";
import IconLargeMXBoots from "@/components/Icon/IconLargeMXBoots.svg";
import IconLargeMXGloves from "@/components/Icon/IconLargeMXGloves.svg";
import IconLargeMXGoggles from "@/components/Icon/IconLargeMXGoggles.svg";
import IconLargeMXHelmet from "@/components/Icon/IconLargeMXHelmet.svg";
import IconLargeMXJersey from "@/components/Icon/IconLargeMXJersey.svg";
import IconLargeMXPants from "@/components/Icon/IconLargeMXPants.svg";
import IconLargeMxProtections from "@/components/Icon/IconLargeMxProtections.svg";

export default function Component({ open, onOpen, onClose, onCloseNavigation }) {
  const menu = {
    title: "MX Gear",
    url: "/mx-gear",
    subMenu: [
      {
        title: "Boots",
        url: "/mx-gear/mx-boots",
        icon: IconLargeMXBoots.src,
        subMenu: [
          {
            title: "Adult Boots",
            url: "/mx-gear/mx-boots/adult-mx-boots",
            count: 46,
          },
          {
            title: "Youth Boots",
            url: "/mx-gear/mx-boots/youth-mx-boots",
            count: 46,
          },
          {
            title: "Kids Boot",
            url: "/mx-gear/mx-boots/kids-mx-boots",
            count: 46,
          },
        ],
      },
      {
        title: "Clearance",
        url: "/mx-gear/mx-clearance",
        icon: IconLargeGearClearance.src,
        subMenu: [
          {
            title: "Under $50",
            url: "/mx-gear/mx-clearance/mx-under-50",
            count: 46,
          },
          {
            title: "Under $100",
            url: "/mx-gear/mx-clearance/mx-under-100",
            count: 46,
          },
          {
            title: "Under $500",
            url: "/mx-gear/mx-clearance/mx-under-500",
            count: 46,
          },
        ],
      },
      {
        title: "Gloves",
        url: "/mx-gear/mx-gloves",
        icon: IconLargeMXGloves.src,
        subMenu: [
          {
            title: "Adult MX Gloves",
            url: "/mx-gear/mx-gloves/adult-mx-gloves",
            count: 46,
          },
          {
            title: "Women's MX Gloves",
            url: "/mx-gear/mx-gloves/womens-mx-gloves",
            count: 46,
          },
          {
            title: "Youth MX Gloves",
            url: "/mx-gear/mx-gloves/youth-mx-gloves",
            count: 46,
          },
        ],
      },
      {
        title: "Goggles",
        url: "/mx-gear/mx-goggles",
        icon: IconLargeMXGoggles.src,
        subMenu: [
          {
            title: "Goggles",
            url: "/mx-gear/mx-goggles/mx-goggles",
            count: 46,
          },
          {
            title: "Goggles Accessories",
            url: "/mx-gear/mx-goggles/mx-goggles-accessories",
            count: 46,
          },
          {
            title: "Tear Offs",
            url: "/mx-gear/mx-goggles/mx-tear-offs",
            count: 46,
          },
          {
            title: "Roll Off Systems",
            url: "/mx-gear/mx-goggles/mx-roll-off-systems",
            count: 46,
          },
          {
            title: "Replacement Lenses",
            url: "/mx-gear/mx-goggles/mx-replacement-lenses",
            count: 46,
          },
        ],
      },
      {
        title: "Helmets",
        url: "/mx-gear/mx-helmets",
        icon: IconLargeMXHelmet.src,
        subMenu: [
          {
            title: "Adult MX Helmets",
            url: "/mx-gear/mx-helmets/adult-mx-helmet",
            count: 46,
          },
          {
            title: "Youth MX Helmets",
            url: "/mx-gear/mx-helmets/youth-mx-helmet",
            count: 46,
          },
        ],
      },
      {
        title: "Jerseys",
        url: "/mx-gear/mx-jersey",
        icon: IconLargeMXJersey.src,
        subMenu: [
          {
            title: "Adult Jerseys",
            url: "/mx-gear/mx-jersey/adult-mx-jersey",
            count: 46,
          },
          {
            title: "Women's Jerseys",
            url: "/mx-gear/mx-jersey/womens-mx-jersey",
            count: 46,
          },
          {
            title: "Youth Jerseys",
            url: "/mx-gear/mx-jersey/youth-mx-jersey",
            count: 46,
          },
          {
            title: "Kids Jerseys",
            url: "/mx-gear/mx-jersey/kids-mx-jersey",
            count: 46,
          },
        ],
      },
      {
        title: "Pants",
        url: "/mx-gear/mx-pants",
        icon: IconLargeMXPants.src,
        subMenu: [
          {
            title: "Adult MX Pants",
            url: "/mx-gear/mx-pants/adult-mx-pants",
            count: 46,
          },
          {
            title: "Women's MX Pants",
            url: "/mx-gear/mx-pants/womens-mx-pants",
            count: 46,
          },
          {
            title: "Youth MX Pants",
            url: "/mx-gear/mx-pants/youth-mx-pants",
            count: 46,
          },
          {
            title: "Kids MX Pants",
            url: "/mx-gear/mx-pants/kids-mx-pants",
            count: 46,
          },
        ],
      },
      {
        title: "Protection",
        url: "/mx-gear/mx-protection",
        icon: IconLargeMxProtections.src,
        subMenu: [
          {
            title: "Back Protection",
            url: "/mx-gear/mx-protection/mx-back-protection",
            count: 46,
          },
          {
            title: "Chest Protection",
            url: "/mx-gear/mx-protection/mx-chest-protection",
            count: 46,
          },
          {
            title: "Ear Protection",
            url: "/mx-gear/mx-protection/mx-ear-protection",
            count: 46,
          },
          {
            title: "Elbow Protection",
            url: "/mx-gear/mx-protection/mx-elbow-protection",
            count: 46,
          },
          {
            title: "Knee Protection",
            url: "/mx-gear/mx-protection/mx-knee-protection",
            count: 46,
          },
          {
            title: "Neck Protection",
            url: "/mx-gear/mx-protection/mx-neck-protection",
            count: 46,
          },
          {
            title: "Protective Undergarments",
            url: "/mx-gear/mx-protection/mx-protective-undergarments",
            count: 46,
          },
          {
            title: "Shoulder Protection",
            url: "/mx-gear/mx-protection/mx-shoulder-protection",
            count: 46,
          },
          {
            title: "Socks",
            url: "/mx-gear/mx-protection/mx-socks",
            count: 46,
          },
          {
            title: "Torso Protection",
            url: "/mx-gear/mx-protection/mx-torso-protection",
            count: 46,
          },
        ],
      },
    ],
  };
  return <SubMenu data={menu} open={open} onOpen={onOpen} onClose={onClose} onCloseNavigation={onCloseNavigation} />;
}
