import classNames from "classnames";
import styles from "./styles.module.scss";

export default function Component({
  className = "",
  value,
  name,
  placeholder,
  options = [],
  rightIcon,
  size = 64, // 50, 56, 64
  onChange,
  ...restProps
}) {
  return (
    <div className="relative w-auto">
      <select
        className={classNames("rounded px-4 font-[700]", styles.selectBox, {
          "py-[10px]": size === 50,
          "py-[14px]": size === 56,
          "py-6": size === 64,
          [className]: className,
        })}
        onChange={onChange}
        value={value}
        {...restProps}
      >
        {placeholder && (
          <option value="" disabled>
            {placeholder}
          </option>
        )}
        {options.map((option, index) => {
          return (
            <option key={`${name}-option-${index}`} value={option.value} className="font-[700] bg-white">
              {option.label}
            </option>
          );
        })}
      </select>
      <div className="absolute top-1/2 right-4 -translate-y-1/2">{rightIcon}</div>
    </div>
  );
}
