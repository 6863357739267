import SubMenu from "./SubMenu";

export default function Component({ open, onOpen, onClose, onCloseNavigation }) {
  const menu = {
    title: "More Information",
    subMenu: [
      {
        title: "Blog",
        url: "/blog",
      },
      {
        title: "About",
        url: "/about",
      },
      {
        title: "Profile",
        url: "/profile",
      },
      {
        title: "Wishlist",
        url: "/profile/wish-list",
      },
      {
        title: "Size charts",
        url: "/about/size-guides",
      },
      {
        title: "Bikebiz gift cards",
        url: " /products/gift-card",
      },
    ],
  };
  return <SubMenu data={menu} open={open} onOpen={onOpen} onClose={onClose} onCloseNavigation={onCloseNavigation} />;
}
