"use client";

import classNames from "classnames";
import { useEffect, useRef, useState } from "react";
import IconSearch from "@/components/Icon/IconSearch.svg";
import IconCloseWhite from "@/components/Icon/IconCloseWhite.svg";
import { useRouter } from "next/navigation";
import fetchProducts from "@/lib/typesense/products/fetchProducts";
import Image from "next/image";
import typesenseClient from "@/lib/typesense/client";

export default function SearchDialog({ className, open, onChange, ...restProps }) {
  const router = useRouter();
  const inputRef = useRef(null);
  const [searchValue, setSearchValue] = useState("");
  const [showSearchResult, setShowSearchResult] = useState(false);
  const [searchResults, setSearchResults] = useState([]);

  const onKeyDown = e => {
    if (e.key === "Enter") {
      router.push(`/search?q=${encodeURIComponent(searchValue)}`);
      onChange(false);
    }
  };
  const fetchProductData = async searchValue => {
    const searchRequests = {
      searches: [
        {
          collection: "bikebiz-ecom",
          query_by: "title, variant_sku_list",
          q: searchValue || "",
          filter_by: "isPublished:true",
          per_page: 100,
          min_len_1typo: 3,
          min_len_2typo: 5,
        },
        {
          collection: "bikebiz-motorcycles",
          query_by: "product_name",
          q: searchValue || "",
          filter_by: "is_published:true",
          per_page: 100,
          min_len_1typo: 3,
          min_len_2typo: 5,
        },
      ],
    };
    const response = await typesenseClient.multiSearch.perform(searchRequests);
    if (response && response.results && response.results.length > 0) {
      const filteredData = [];
      const ecomData = response.results[0].hits.map(hit => hit.document);
      const vehicleData = response.results[1].hits.map(hit => hit.document);
      if (ecomData && ecomData.length > 0) {
        filteredData.push(
          ...ecomData.map(product => {
            return {
              name: product.title,
              slug: `/products/${product.url_key}`,
              type: "department",
              variant_sku_list: product.variant_sku_list,
            };
          })
        );
      }
      if (vehicleData && vehicleData.length > 0) {
        filteredData.push(
          ...vehicleData.map(product => {
            var urlProduct = "";
            if (product.type === "new_bike")
              if (product.product_range_slugs && product.product_range_slugs.length > 0) {
                urlProduct = `/new-bikes/${product.manufacturer.toLowerCase()}/${product.product_range_slugs[0]}/${
                  product.prismic_uid
                }`;
              } else {
                urlProduct = `/new-bikes/${product.manufacturer.toLowerCase()}/${product.prismic_uid}`;
              }
            if (product.type === "used_bike") urlProduct = `/used-bikes/${product.prismic_uid}`;
            return {
              name: product.product_name,
              slug: urlProduct,
              type: "vehicle",
              variant_sku_list: [],
            };
          })
        );
      }
      filteredData.sort((a, b) => {
        return a.name.localeCompare(b.name);
      });
      setSearchResults(filteredData);
    }
  };

  useEffect(() => {
    setSearchValue("");
    setSearchResults([]);
  }, [showSearchResult]);

  useEffect(() => {
    if (open && inputRef.current) {
      inputRef.current.focus();
    }
    setSearchValue("");
    setSearchResults([]);
  }, [open]);
  return (
    <div className={`dialog__slide ${open ? "show" : ""}`}>
      <div className="dialog__content">
        <div className={classNames("w-screen h-screen font-roboto bg-white fixed top-0 left-0 z-[50]")}>
          <div className="h-[64px] flex items-center border-b-[1px] border-grey">
            <input
              ref={inputRef}
              placeholder="Search Bikebiz"
              className="w-full h-full bg-transparent outline-none  py-[18px] px-8 text-base"
              value={searchValue}
              onChange={e => {
                setSearchValue(e.target.value);
                setShowSearchResult(true);
                fetchProductData(e.target.value);
              }}
              onClick={() => setShowSearchResult(true)}
              onKeyDown={e => onKeyDown(e)}
            />
            <Image src={IconSearch.src} width={30} height={30} />
            <button className="h-full min-w-[63px] bg-black flex items-center ml-5">
              <Image
                src={IconCloseWhite.src}
                width={20}
                height={20}
                className="text-white mx-auto"
                onClick={() => onChange(false)}
              />
            </button>
          </div>
          {searchResults.length > 0 && (
            <div className="w-full my-4">
              <div className="w-full ">
                {searchResults.slice(0, 10).map((result, index) => {
                  const searchBySku = result.variant_sku_list.find(sku => sku.includes(searchValue));
                  return (
                    <div
                      key={`search-result-${index}`}
                      onClick={() => {
                        router.push(result.slug);
                        onChange(false);
                      }}
                      className="cursor-pointer"
                    >
                      <div className="flex items-center gap-3 py-1 px-[30px] my-1 bg-white hover:bg-grey-100">
                        <div className="-[303px] whitespace-nowrap overflow-hidden text-ellipsis">
                          {searchBySku ? (
                            <>
                              <b>{result.name}</b> ({searchBySku})
                            </>
                          ) : (
                            <>
                              {searchValue}
                              <b>
                                {result.name &&
                                  result.name.substring(result.name.indexOf(searchValue) + searchValue.length + 1)}
                              </b>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>

              <div className="flex items-center px-[30px] my-3">
                <div
                  onClick={() => {
                    router.push(`/search?q=${encodeURIComponent(searchValue)}`);
                    onChange(false);
                  }}
                >
                  <div className="underline cursor-pointer">View all results for ‘{searchValue}’</div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
