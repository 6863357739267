import { searchInCollection } from "../typesenseFetchClient";

export default async function fetchMotorcycles(parameters) {
  try {
    if (!parameters.filter_by) parameters.filter_by = "is_published:true";
    else parameters.filter_by += " && is_published:true";
    const searchParameters = {
      max_facet_values: 1000,
      q: "",
      query_by: "title",
      ...parameters,
    };

    const data = await searchInCollection("bikebiz-motorcycles", searchParameters);

    return data;
  } catch (error) {
    console.error("Error:", error);
    return null;
  }
}
