"use client";
import * as Switch from "@radix-ui/react-switch";
import styles from "./styles.module.scss";
import classNames from "classnames";
import Image from "next/image";
import IconCheck from "@/components/Icon/IconCheck.svg";

export default function Component({
  className,
  value,
  size,
  showCheckIcon = false,
  onChange,
  thumbColor,
  ...restProps
}) {
  return (
    <div className="relative h-[32px]">
      <Switch.Root
        className={classNames(styles.SwitchRoot, styles[size], styles[thumbColor], { [className]: className })}
        checked={value}
        onCheckedChange={onChange}
        {...restProps}
      >
        <Switch.Thumb className={classNames(styles.SwitchThumb, styles[size])} />
      </Switch.Root>
      {value && showCheckIcon && (
        <Image
          src={IconCheck.src}
          alt="IconCheck"
          width={18}
          height={18}
          className="w-[18px] h-[18px] absolute top-[7px] right-[6px]"
        />
      )}
    </div>
  );
}
