import { searchInCollection } from "../typesenseFetchClient";

export default async function fetchVehicles(parameters) {
  try {
    if (parameters.filter_by) {
      parameters.filter_by = parameters.filter_by.replaceAll(/[()]/g, "");
    }
    const searchParameters = {
      max_facet_values: 1000,
      q: "",
      query_by: "name",
      ...parameters,
    };

    const data = await searchInCollection("bikebiz-vehicles", searchParameters);

    return data;
  } catch (error) {
    console.error("Error:", error);
    return null;
  }
}
