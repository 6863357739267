"use client";

import { useCart } from "@/lib/saleor/reactSaleor";

export default function CartButton({}) {
  const { items } = useCart();

  return (
    items &&
    items.length > 0 && (
      <span className="w-4 h-4 bg-gold text-xs font-bold flex items-center justify-center absolute bottom-1/2 left-1/2 translate-x-1/4 -translate-y-1/4 rounded-full">
        {items.reduce((acc, item) => acc + item.quantity, 0)}
      </span>
    )
  );
}
