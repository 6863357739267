"use client";
import classNames from "classnames";
import styles from "./styles.module.scss";
import Link from "next/link";

import DropDown from "@/components/DropDown";
import Switcher from "@/components/Switcher";
import { useRouter } from "next/navigation";
import { useEffect, useState } from "react";
import IconArrowRight from "@/components/Icon/IconArrowRight.svg";
import IconArrowLeft from "@/components/Icon/IconArrowLeft.svg";
import IconArrowCaretDown from "@/components/Icon/IconArrowCaretDown.svg";
import Image from "next/image";

import fetchMotorcycles from "@/lib/typesense/motorcycles/fetchMotorcycles";
import { convertToSlug } from "@/utils/common";

export default function Component({ open, onClose, onCloseNavigation }) {
  const router = useRouter();
  const [makeValue, setMakeValue] = useState("");
  const [modelValue, setModelValue] = useState("");
  const [makeOptions, setMakeOptions] = useState([]);
  const [modelOptions, setModelOptions] = useState([]);
  const [isSearchLAMS, setIsSearchLAMS] = useState(false);

  const fetchMakeData = async () => {
    const response = await fetchMotorcycles({
      facet_by: "manufacturer",
      filter_by: `type:used_bike`,
      include_fields: "",
    });
    if (response.facet_counts.length > 0 && response.facet_counts[0]?.counts.length > 0) {
      const options = response.facet_counts[0]?.counts.map(item => {
        return {
          label: item.value,
          value: item.value,
        };
      });
      options.sort((a, b) => a.label.localeCompare(b.label));
      setMakeOptions([{ label: "All Makes", value: "" }, ...options]);
      setModelOptions([]);
      setModelValue("");
    }
  };
  const fetchModelData = async make => {
    const response = await fetchMotorcycles({
      facet_by: "model",
      filter_by: `type:used_bike && manufacturer:${make}`,
      include_fields: "",
    });
    if (response.facet_counts.length > 0 && response.facet_counts[0]?.counts.length > 0) {
      const options = response.facet_counts[0]?.counts.map(item => {
        return {
          label: item.value,
          value: item.value,
        };
      });
      options.sort((a, b) => a.label.localeCompare(b.label));
      setModelOptions([{ label: "All Models", value: "" }, ...options]);
    }
  };

  const handleSearchUsedBikes = e => {
    e.preventDefault();
    var url = `/used-bikes/all`;
    const params = new URLSearchParams();
    if (makeValue) {
      params.set(`make`, convertToSlug(makeValue));
    }
    if (modelValue) {
      params.set(`model`, convertToSlug(modelValue));
    }
    if (isSearchLAMS) {
      params.set(`lam`, true);
    }
    router.push(`/used-bikes/all?${params.toString()}`);
    onCloseNavigation(false);
  };
  useEffect(() => {
    fetchMakeData();
  }, []);

  const handleBackToMenu = () => {
    document.querySelector(`#menu-bar`)?.classList.add("show-menu");
    document.querySelector(`#menu-bar`)?.classList.remove("hidden-menu");
    onClose();
  };

  return (
    <div
      id="used-bikes-menu"
      className={classNames(styles.subMenu, styles.subMenu1, open ? styles["show-menu"] : styles["hidden-menu"])}
    >
      <div
        className="w-full py-5 flex lg:hidden items-center gap-2 px-5 border-b-[1px] border-grey cursor-pointer"
        onClick={handleBackToMenu}
      >
        <Image src={IconArrowLeft.src} width="16" height="16" alt="IconArrowLeft" />
        <p className={classNames("text-sm font-bold font-roboto")}>Back</p>
      </div>
      <div className="w-full h-fit flex justify-between items-center px-6 py-3 lg:py-7">
        <h5 className="ml-2">Used Motorcycles</h5>
      </div>
      <div className="w-full px-2 lg:px-7">
        <form
          className="w-full flex flex-col gap-4 py-[26px] lg:py-[30px] px-[16px] lg:px-8 bg-black rounded-[4px]"
          onSubmit={handleSearchUsedBikes}
        >
          <p className="text-gold text-xl font-bold">Search used motorcycles</p>

          <div className="w-full flex flex-col gap-4">
            <DropDown
              className=" bg-white w-full"
              name="Make"
              placeholder="Make"
              value={makeValue}
              options={makeOptions}
              rightIcon={<Image src={IconArrowCaretDown.src} width={10} height={10} alt="IconArrowCaretDown" />}
              size={56}
              onChange={e => {
                setMakeValue(e.target.value);
                setModelValue("");
                if (e.target.value) {
                  fetchModelData(e.target.value);
                } else {
                  setModelOptions([]);
                }
              }}
            />
            <DropDown
              className="bg-white w-full"
              name="Models"
              placeholder="Models"
              value={modelValue}
              options={modelOptions}
              rightIcon={<Image src={IconArrowCaretDown.src} width={10} height={10} alt="IconArrowCaretDown" />}
              size={56}
              onChange={e => setModelValue(e.target.value)}
              disabled={!makeValue}
            />
          </div>
          <div className="flex justify-between items-center">
            <label className="text-sm text-white flex flex-col lg:flex-row gap-1">
              <span>
                Search <b>LAMS</b>
              </span>
              <span>motorcycles only</span>
            </label>
            <Switcher value={isSearchLAMS} thumbColor="green" onChange={() => setIsSearchLAMS(!isSearchLAMS)} />
          </div>
          <button
            type="submit"
            className="w-full rounded-[4px] py-3 text-base border-[1px] border-grey bg-transparent text-white font-bold mb-3"
          >
            SHOP NOW
          </button>
        </form>
      </div>
      <div className="mt-6 lg:mt-[56px]">
        <p className="px-6 lg:px-7 mb-1 text-grey text-sm">Quick Links</p>
        <div className={classNames("w-full px-6 lg:px-7 hover:bg-light-grey")}>
          <Link href="/used-bikes/all" onClick={() => onCloseNavigation(false)}>
            <div className="w-full h-[56px] flex items-center gap-4 border-b-[1px] border-grey-100 justify-between">
              <p className="text-base font-[500]">Browse the range</p>
              <Image src={IconArrowRight.src} width="24" height="24" alt="IconArrowRight" />
            </div>
          </Link>
        </div>
        <div className={classNames("w-full px-6 lg:px-7 hover:bg-light-grey")}>
          <Link href="/used-bikes/all?lams=true" onClick={() => onCloseNavigation(false)}>
            <div className="w-full h-[56px] flex items-center gap-4 border-b-[1px] border-grey-100 justify-between">
              <p className="text-base font-[500]">Browse all LAMS</p>
              <Image src={IconArrowRight.src} width="24" height="24" alt="IconArrowRight" />
            </div>
          </Link>
        </div>
      </div>
      <div className="mt-6 lg:mt-8">
        <p className="px-6 lg:px-7 mb-1 text-grey text-sm">Want to sell your used motorcycle?</p>
        <div className={classNames("w-full px-6 lg:px-7 hover:bg-light-grey")}>
          <Link href="/used-bikes#sell" onClick={() => onCloseNavigation(false)}>
            <div className="w-full h-[56px] flex items-center gap-4 border-b-[1px] border-grey-100 justify-between">
              <p className="text-base font-[500]">Sell your bike here</p>
              <Image src={IconArrowRight.src} width="24" height="24" alt="IconArrowRight" />
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
}
