const protocol = process.env.NEXT_PUBLIC_TYPESENSE_PROTOCOL;
const host = process.env.NEXT_PUBLIC_TYPESENSE_HOST;
const apiKey = process.env.NEXT_PUBLIC_TYPESENSE_API_KEY;

const rootUrl = `${protocol}://${host}`;

export const searchInCollection = async (collectionName, parameters) => {
  try {
    const url = rootUrl + `/collections/${collectionName}/documents/search?` + new URLSearchParams(parameters);
    const res = await fetch(url, {
      headers: {
        accept: "application/json, text/plain, */*",
        "x-typesense-api-key": apiKey,
      },
      cache: "no-store",
    });

    if (!res.ok) {
      throw new Error(`Error fetching: ${res.statusText}`);
    }
    return res.json();
  } catch (error) {
    console.error("Error when fetching:", error);
    throw error;
  }
};
