import { searchInCollection } from "../typesenseFetchClient";

export default async function fetchProducts(parameters) {
  try {
    if (!parameters.filter_by) parameters.filter_by = "isPublished:true";
    else parameters.filter_by += " && isPublished:true";
    if (!parameters.sort_by) {
      parameters.sort_by =
        "_eval([ (price:>300):3, (price:>200):2, (best_buys:true):3]):desc, bayesianAverageRating:desc";
    }

    const searchParameters = {
      max_facet_values: 1000,
      q: "",
      query_by: "title",
      include_fields: `id, url_key, title, image, vehicle_ids, colour, discount_price, price, rating, review_count,best_buys, storefrontId, categories, brand, sku, on_discount, discount_price, primaryId, bayesianAverageRating`,
      ...parameters,
    };

    const data = await searchInCollection("bikebiz-ecom", searchParameters);

    return data;
  } catch (error) {
    console.error("Error:", error);
    return null;
  }
}
